import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { AutoLoginGuard } from './guards/auto-login.guard';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
      },
      {
        path: 'starter',
        loadChildren: () =>
          import('./pages/pages.module').then((m) => m.PagesModule),
      },
      {
        path: 'dashboards',
        loadChildren: () =>
          import('./pages/dashboards/dashboards.module').then(
            (m) => m.DashboardsModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'ui-components',
        loadChildren: () =>
          import('./pages/ui-components/ui-components.module').then(
            (m) => m.UicomponentsModule
          ),
      },
      {
        path: 'forms',
        loadChildren: () =>
          import('./pages/forms/forms.module').then((m) => m.FormModule),
      },
      {
        path: 'charts',
        loadChildren: () =>
          import('./pages/charts/charts.module').then((m) => m.ChartsModule),
      },
      {
        path: 'apps',
        loadChildren: () =>
          import('./pages/apps/apps.module').then((m) => m.AppsModule),
      },
      {
        path: 'widgets',
        loadChildren: () =>
          import('./pages/widgets/widgets.module').then((m) => m.WidgetsModule),
      },
      {
        path: 'tables',
        loadChildren: () =>
          import('./pages/tables/tables.module').then((m) => m.TablesModule),
      },
      {
        path: 'datatable',
        loadChildren: () =>
          import('./pages/datatable/datatable.module').then((m) => m.DatatableModule),
      },
      {
        path: 'theme-pages',
        loadChildren: () =>
          import('./pages/theme-pages/theme-pages.module').then(
            (m) => m.ThemePagesModule
          ),
      },
      {
        path: 'dashboard',
        loadComponent: () =>
          import('./pagesCustom/dashboard/dashboard.component').then(
            (m) => m.DashboardComponent
          ),
          title: 'Dashboard'
      },
      {
        path: 'tags',
        loadComponent: () =>
          import('./pagesCustom/tagging-tool/tagging-tool.component').then(
            (m) => m.TaggingToolComponent
          ),
          title: 'Tagging Tool'
      },
      {
        path: 'gpu-pricing',
        loadComponent: () =>
          import('./pagesCustom/gpu-pricing/gpu-pricing.component').then(
            (m) => m.GpuPricingComponent
          ),
          title: 'GPU Pricing'
      },
      {
        path: 'providers',
        loadComponent: () =>
          import('./pagesCustom/providers/providers.component').then(
            (m) => m.ProvidersComponent
          ),
          title: 'Manage Providers'
      },
      {
        path: 'cost-analyzer',
        loadComponent: () =>
          import('./pagesCustom/cost-analyzer/cost-analyzer.component').then(
            (m) => m.CostAnalyzerComponent
          ),
          title: 'Daily Cloud Cost Analyzer'
      },
      {
        path: 'my-profile',
        loadComponent: () =>
          import('./pagesCustom/my-profile/my-profile.component').then(
            (m) => m.MyProfileComponent
          ),
          title: 'My Profile'
      },
    ],
    canLoad: [AuthGuard],
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren: () =>
          import('./pages/authentication/authentication.module').then(
            (m) => m.AuthenticationModule
          ),
        canLoad: [AutoLoginGuard],
      },
      {
        path: 'landingpage',
        loadChildren: () =>
          import('./pages/theme-pages/landingpage/landingpage.module').then(
            (m) => m.LandingPageModule
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
