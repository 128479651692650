import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { from, Observable,lastValueFrom, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handleAccess(request, next)).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          // Handle 401 error here (e.g., redirect to login page or perform any desired action)
          // You can also throw an error to propagate it to the caller
          // throw new Error('Unauthorized');
          console.log('ERROR AUTH')
          this.authService.logout();
          this.router.navigateByUrl('/authentication/login', { replaceUrl: true });
        }
        return throwError(() => error);
      })
    );
  }

  private async handleAccess(request: HttpRequest<any>, next: HttpHandler):Promise<HttpEvent<any>> {
    const token = await this.authService.getToken();
    const isApiUrl = request.url.startsWith(environment.apiURL);

    if (token && isApiUrl) {
        request = request.clone({
            setHeaders: { 
                Authorization: `Bearer ${token}`,
                // 'x-platform': environment.platform
            }
        });
    } 
    // else if(isApiUrl) {
    //   request = request.clone({
    //     setHeaders: {
    //         'x-platform': environment.platform
    //     }
    //   });
    // }

    return lastValueFrom(next.handle(request));
  }
}