import { Injectable } from '@angular/core';
import { BehaviorSubject, from, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const apiURLs = {
  root: environment.apiURL,
  login: '/token/',
  register: '/user/register/',
  // refreshToken: '/token/refresh',
  forgotPswd: '/user/password/forgotten/',
  setPswd: '/user/password/reset/', // + token (signature)
};

const TOKEN_KEY = 'token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isAuthenticated: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
  token = '';

  constructor(
    private http: HttpClient,
  ) { 
    this.loadToken();
  }

  async loadToken() {
    const token = await localStorage.getItem(TOKEN_KEY + 'Access');    
    if (token && token) {
      console.log('set token: ', token);
      this.token = token;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  public login(credentials : {username: string, password: string}): Observable<any> {
    return this.http.post(apiURLs.root + apiURLs.login, credentials).pipe(
      map((data: any) => data),
      switchMap(data => {
        console.log(data)
        this.setRefreshToken(data.refresh);
        this.setToken(data.access);
        return of(data);
      }),
      tap(_ => {
        this.isAuthenticated.next(true);
      })
    );
  }

  setToken(accessToken: string) {
    return localStorage.setItem(TOKEN_KEY + 'Access', accessToken);
  }

  setRefreshToken(refreshToken: string) {
    return localStorage.setItem( TOKEN_KEY + 'Refresh', refreshToken);
  }

  async getToken() {
    const token = await localStorage.getItem(TOKEN_KEY + 'Access');
    return token; 
  }

  public logout(): void {
    this.isAuthenticated.next(false);
    return localStorage.removeItem(TOKEN_KEY + 'Access');
  }

  public forgotPassword(email: {email: string}): Observable<any> {
    return this.http.post(apiURLs.root + apiURLs.forgotPswd, email);
  }

  public setPassword(data: {token: string | null, newPassword: string}): Observable<any> {
    return this.http.put(apiURLs.root + apiURLs.setPswd + data.token + '/', {password1: data.newPassword, password2: data.newPassword});
  }

  public register(data: { email: string }): Observable<any> {
    return this.http.post(apiURLs.root + apiURLs.register, data)
  }
}
