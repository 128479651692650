<mat-toolbar class="topbar">
  <!-- --------------------------------------------------------------- -->
  <!-- Desktop Menu -->
  @if(showToggle) {
  <button
    mat-icon-button
    (click)="toggleCollapsed.emit()"
    class="d-flex justify-content-center"
  >
    <i-tabler name="align-left" class="icon-20 d-flex"></i-tabler>
  </button>
  }

  <!-- Mobile Menu -->
  @if(!showToggle) {
  <button
    mat-icon-button
    (click)="toggleMobileNav.emit()"
    class="d-flex justify-content-center"
  >
    <i-tabler name="align-left" class="icon-20 d-flex"></i-tabler>
  </button>
  }

  <!-- --------------------------------------------------------------- -->

  <span class="flex-1-auto"></span>

  <!-- --------------------------------------------------------------- -->
  <!--  Search -->
  <!-- --------------------------------------------------------------- -->
  <!-- <button mat-stroked-button class="d-none d-lg-flex" (click)="openDialog()">
    <div class="d-flex align-items-center">
      <i-tabler name="search" class="icon-20 d-flex m-r-10"></i-tabler> Try to
      Searching...
    </div>
  </button> -->

  <!-- --------------------------------------------------------------- -->
  <!-- langugage Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <!-- <button [matMenuTriggerFor]="flags" mat-icon-button class="m-l-10">
    <img
      [src]="selectedLanguage.icon"
      class="rounded-circle object-cover icon-20"
    />
  </button>
  <mat-menu #flags="matMenu" class="cardWithShadow">
    @for(lang of languages; track lang) {
    <button mat-menu-item (click)="changeLanguage(lang)">
      <div class="d-flex align-items-center">
        <img
          [src]="lang.icon"
          class="rounded-circle object-cover m-r-8 icon-20"
        />
        <span class="mat-subtitle-1 f-s-14">{{ lang.language }}</span>
      </div>
    </button>
    }
  </mat-menu> -->

  <!-- --------------------------------------------------------------- -->
  <!-- Messages Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <!-- <button mat-icon-button [matMenuTriggerFor]="msgMenu" aria-label="Messages">
    <i-tabler class="d-flex" name="message-2"></i-tabler>
    <div class="pulse">
      <span class="heartbit border-primary"></span>
      <span class="point bg-primary"></span>
    </div>
  </button>
  <mat-menu
    #msgMenu="matMenu"
    xPosition="before"
    class="topbar-dd cardWithShadow"
  >
    <div class="d-flex align-items-center p-x-32 p-y-16">
      <h6 class="f-s-16 f-w-600 m-0 mat-subtitle-1">Messages</h6>
      <span class="m-l-16">
        <span class="bg-warning text-white p-x-8 p-y-4 f-w-500 rounded f-s-12"
          >5 new</span
        >
      </span>
    </div>
    @for(notification of notifications; track notification.title) {
    <button mat-menu-item class="p-x-32 p-y-16">
      <div class="d-flex align-items-center">
        <img [src]="notification.img" class="rounded-circle" width="48" />
        <div class="m-l-16">
          <h5 class="f-s-14 f-w-600 m-0 mat-subtitle-1">
            {{ notification.title }}
          </h5>
          <span>{{ notification.subtitle }}</span>
        </div>
      </div>
    </button>
    }

    <div class="p-y-12 p-x-32">
      <button mat-flat-button color="primary" class="w-100">
        See All Messages
      </button>
    </div>
  </mat-menu> -->

  <!-- --------------------------------------------------------------- -->
  <!-- Notification Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <!-- <button
    mat-icon-button
    [matMenuTriggerFor]="notificationMenu"
    aria-label="Notifications"
  >
    <i-tabler class="d-flex" name="bell"></i-tabler>
    <div class="pulse">
      <span class="heartbit border-success"></span>
      <span class="point bg-success"></span>
    </div>
  </button>
  <mat-menu
    #notificationMenu="matMenu"
    xPosition="before"
    class="topbar-dd cardWithShadow"
  >
    <div class="d-flex align-items-center p-x-32 p-y-16">
      <h6 class="f-s-16 f-w-600 m-0 mat-subtitle-1">Notifications</h6>
      <span class="m-l-16">
        <span class="bg-warning text-white p-x-8 p-y-4 f-w-500 rounded f-s-12"
          >5 new</span
        >
      </span>
    </div>

    @for(notification of notifications; track notification.title) {
    <button mat-menu-item class="p-x-32 p-y-16">
      <div class="d-flex align-items-center">
        <img [src]="notification.img" class="rounded-circle" width="48" />
        <div class="m-l-16">
          <h5 class="f-s-14 f-w-600 m-0 mat-subtitle-1">
            {{ notification.title }}
          </h5>
          <span>{{ notification.subtitle }}</span>
        </div>
      </div>
    </button>
    }

    <div class="p-y-12 p-x-32">
      <button mat-flat-button color="primary" class="w-100">
        See All Notifications
      </button>
    </div>
  </mat-menu> -->

  <!-- --------------------------------------------------------------- -->
  <!-- profile Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <button
    mat-fab
    extended
    color="inherit"
    [matMenuTriggerFor]="profileMenu"
    class="disabled-hover"
    aria-label="Notifications"
  >
    <div class="d-flex align-items-center">
      <img
        src="/assets/images/profile/user5.jpg"
        class="rounded-circle object-cover"
        width="40"
      />

      <div class="m-l-16 text-left d-none d-lg-flex">
        <div>
          <h5 class="f-s-16">{{userData?.first_name || 'Settings'}} {{userData?.last_name || ''}}</h5>
          <!-- <span class="f-s-14 mat-body-1">Admin</span> -->
        </div>
      </div>
    </div>
  </button>
  <mat-menu
    #profileMenu="matMenu"
    xPosition="before"
    class="topbar-dd cardWithShadow"
  >
    <ng-scrollbar class="position-relative" style="height: 345px"> <!-- was 495px-->
      <div class="p-x-32 p-y-16">
        <h6 class="f-s-16 f-w-600 m-0 mat-subtitle-1">User Profile</h6>

        <div class="d-flex align-items-center p-b-24 b-b-1 m-t-16">
          <img
            src="/assets/images/profile/user5.jpg"
            class="rounded-circle"
            width="55"
          />
          <div class="m-l-16">
            <h6 class="f-s-16 f-w-600 m-0 mat-subtitle-1">{{userData?.first_name}} {{userData?.last_name}}</h6>
            <!-- <span class="f-s-14 mat-body-1 d-block m-b-4">Admin</span> -->
            <span class="d-flex align-items-center" style="max-width: 185px;">
              <!-- <i-tabler name="mail" class="icon-15 m-r-4"></i-tabler> -->
              {{userData?.email}}
            </span>
          </div>
        </div>
      </div>
      <div class="p-x-32">
        @for(profile of profiledd; track profile.title) {
        <a
          class="p-y-16 text-decoration-none d-block text-hover-primary"
          [routerLink]="[profile.link]"
        >
          <div class="d-flex align-items-center">
            <span
              class="text-{{ profile.color }} bg-light-{{
                profile.color
              }} icon-50 d-flex align-items-center justify-content-center shadow-none rounded"
            >
              <i-tabler [name]="profile.img" class="icon-20" />
            </span>

            <div class="m-l-16">
              <h5
                class="f-s-14 f-w-600 m-0 textprimary mat-subtitle-1 hover-text"
              >
                {{ profile.title }}
              </h5>
              <span class="mat-body-1">{{ profile.subtitle }}</span>
            </div>
          </div>
        </a>
        }
      </div>

      <div class="p-y-12 p-x-32">
        <button
          (click)="logout()"
          mat-flat-button
          color="primary"
          class="w-100"
        >
          Logout
        </button>
      </div>
    </ng-scrollbar>
  </mat-menu>
</mat-toolbar>
